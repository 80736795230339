
import {
  IonPage,
  IonContent,
  modalController,
  alertController,
} from "@ionic/vue";
import { createOutline, trashOutline } from "ionicons/icons";
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import EditAddress from "@/components/EditAddressModal.vue";
import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Address",
  setup() {
    const { t, te } = useI18n();
    const { createLoading, openToast } = useGlobalFunctions();
    const icons = reactive({
      createOutline,
      trashOutline,
    });
    const storeX = useStore();
    const route = useRoute();
    const openAddressId = ref(route.params.address_id);

    const domainLangUrl = window.location.origin;
    const isAE = domainLangUrl.includes("ae.yallatager");
    const countryCode = isAE ? "AE" : "EG";

    const defaultAddress = ref(null);
    const addresses = ref([]);

    const getAllAddresses = async () => {
      defaultAddress.value = null;
      addresses.value = [];
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("getAllAddresses"))
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            let finishedProc = 0;
            const otherAddress = [];
            for (let i = 0; i < response.items.length; i++) {
              const item = response.items[i];
              if (item.is_default_shipping) {
                defaultAddress.value = item;
              } else {
                otherAddress.push(item);
              }
              finishedProc++;
              if (finishedProc == response.items.length) {
                addresses.value = otherAddress;
              }
              if (
                openAddressId.value &&
                item.entity_id == openAddressId.value
              ) {
                const modal = await modalController.create({
                  component: EditAddress,
                  cssClass: "addressModal",
                  componentProps: {
                    address: item,
                    dismiss: () => {
                      modal.dismiss();
                      getAllAddresses();
                    },
                  },
                });
                modal.present();
                openAddressId.value = "0";
              }
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const addressLineString = (address: any) => {
      let streetString = address.street[0].trim();
      if (address.street[1].trim().length) {
        streetString += ", " + address.street[1];
      }
      if (address.street[2].trim().length) {
        streetString += ", " + address.street[2];
      }
      if (address.street[3].trim().length) {
        streetString += ", " + address.street[3];
      }
      return streetString;
    };

    const deleteAddress = async (addressId: any) => {
      event.preventDefault();
      const alert = await alertController.create({
        message: t("areYouSure"),
        buttons: [
          {
            text: t("cancel"),
            role: "cancel",
          },
          {
            text: t("delete"),
            cssClass: "primary",
            handler: async () => {
              const loading = await createLoading(t("pleaseWait"));
              loading.present();
              httpApp
                .sendPostRequest(settingsApp.getEndpointUrl("deleteAddress"), {
                  address_id: addressId,
                })
                .then(async (response: any) => {
                  if (response.success) {
                    getAllAddresses();
                  } else {
                    if (te("apiMsgs." + response.error.code)) {
                      openToast(t("apiMsgs." + response.error.code), "danger");
                    } else {
                      openToast(response.error.message, "danger");
                    }
                  }
                  loading.dismiss();
                })
                .catch((error) => {
                  loading.dismiss();
                  console.log(error, "error");
                });
            },
          },
        ],
      });
      alert.present();
    };
    const openAddAddressModal = async () => {
      const modal = await modalController.create({
        component: EditAddress,
        cssClass: "addressModal",
        componentProps: {
          address: {
            entity_id: 0,
            address_label: "",
            firstname: storeX.state.customerData.account_data.first_name,
            lastname: storeX.state.customerData.account_data.last_name,
            telephone: storeX.state.customerData.account_data.phone,
            street1: "",
            region_id: "",
            region: "",
            city: "",
            city_id: "",
            country_id: countryCode,
            epowerbackend_lat: "",
            epowerbackend_lng: "",
            is_default_shipping: 1,
            is_default_billing: 1,
          },
          dismiss: () => {
            modal.dismiss();
            getAllAddresses();
          },
        },
      });
      return modal.present();
    };

    const openEditAddressModal = async (address: any) => {
      const modal = await modalController.create({
        component: EditAddress,
        cssClass: "addressModal",
        componentProps: {
          address,
          dismiss: () => {
            modal.dismiss();
            getAllAddresses();
          },
        },
      });
      return modal.present();
    };

    function ionViewWillEnter() {
      getAllAddresses();
    }

    return {
      t,
      te,
      icons,
      openAddAddressModal,
      openEditAddressModal,
      ionViewWillEnter,
      defaultAddress,
      addresses,
      addressLineString,
      deleteAddress,
      getAllAddresses,
    };
  },
  components: {
    IonPage,
    IonContent,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});
