
import { defineComponent, reactive, ref } from "vue";
import { GoogleMap, Marker, CustomControl } from "vue3-google-map";
import { LMap, LTileLayer, LMarker, LControl } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  close,
  personOutline,
  callOutline,
  mapOutline,
  locateOutline,
  pencilOutline,
} from "ionicons/icons";
import settingsApp from "@/libs//settings";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";
import { Geolocation } from "@ionic-native/geolocation";
import { useStore } from "@/store";

import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from "@ionic/vue";
export default defineComponent({
  name: "EditAddress",
  props: {
    address: Object,
    dismiss: Function,
  },
  setup(props: any) {
    const { t, te, tm } = useI18n();
    const { getLocalStorageData } = useLocalStorage();
    const { createLoading, openToast } = useGlobalFunctions();
    const storeX = useStore();
    const icons = reactive({
      close,
      personOutline,
      callOutline,
      mapOutline,
      locateOutline,
      pencilOutline,
    });

    const mapService = ref(getLocalStorageData("mapService"));
    const addressGoogleApiKey = ref(getLocalStorageData("addressGoogleApiKey"));

    const stateCities = ref([]);
    const gettingCities = ref(true);

    const domainLangUrl = window.location.origin;
    const isAE = domainLangUrl.includes("ae.yallatager");
    const countryCode = isAE ? "AE" : "EG";

    const addressToEdit = reactive({
      entity_id: props.address.entity_id,
      address_label: props.address.address_label,
      firstname: props.address.firstname,
      lastname: props.address.lastname,
      telephone: props.address.telephone,
      street1: props.address.street,
      region_id: props.address.region_id,
      state: props.address.region,
      city: props.address.city,
      city_id: props.address.city_id,
      country_id: countryCode,
      epowerbackend_lat: props.address.epowerbackend_lat,
      epowerbackend_lng: props.address.epowerbackend_lng,
      is_default_shipping: props.address.is_default_shipping,
      is_default_billing: props.address.is_default_billing,
    });

    const showMap = ref(false);
    const addressPosition = ref({ lat: null, lng: null });

    const getPosition = (userClicked = false) => {
      Geolocation.getCurrentPosition({
        timeout: 3000,
        enableHighAccuracy: true,
      })
        .then((resp) => {
          if (userClicked) {
            addressPosition.value.lat = Number(resp.coords.latitude);
            addressPosition.value.lng = Number(resp.coords.longitude);
            addressToEdit.epowerbackend_lat = Number(resp.coords.latitude);
            addressToEdit.epowerbackend_lng = Number(resp.coords.longitude);
          }
          showMap.value = false;
          setTimeout(() => {
            showMap.value = true;
          }, 5);
        })
        .catch(() => {
          addressPosition.value.lat = null;
          addressPosition.value.lng = null;
          openToast(t("errorMsgs.failPosition"), "warning");
          showMap.value = true;
        });
    };

    if (props.address.epowerbackend_lat && props.address.epowerbackend_lat) {
      addressPosition.value.lat = Number(addressToEdit.epowerbackend_lat);
      addressPosition.value.lng = Number(addressToEdit.epowerbackend_lng);
      setTimeout(() => {
        showMap.value = true;
      }, 5);
    } else {
      getPosition();
    }

    const mapClicked = (event) => {
      if (Object.prototype.hasOwnProperty.call(event, "latlng")) {
        addressPosition.value.lat = Number(event.latlng.lat);
        addressPosition.value.lng = Number(event.latlng.lng);
        addressToEdit.epowerbackend_lat = Number(event.latlng.lat);
        addressToEdit.epowerbackend_lng = Number(event.latlng.lng);
        showMap.value = false;
        setTimeout(() => {
          showMap.value = true;
        }, 5);
      }
    };

    const addressDragend = (event) => {
      if (mapService.value == "google_maps") {
        addressPosition.value.lat = Number(event.latLng.lat());
        addressPosition.value.lng = Number(event.latLng.lng());
        addressToEdit.epowerbackend_lat = Number(event.latLng.lat());
        addressToEdit.epowerbackend_lng = Number(event.latLng.lng());
      } else if (mapService.value == "osm_leaflet") {
        addressPosition.value.lat = Number(event.target._latlng.lat);
        addressPosition.value.lng = Number(event.target._latlng.lng);
        addressToEdit.epowerbackend_lat = Number(event.target._latlng.lat);
        addressToEdit.epowerbackend_lng = Number(event.target._latlng.lng);
      }
    };

    const addressToEditRules = {
      firstname: { required },
      lastname: { required },
      telephone: { required },
      street1: { required },
      state: { required },
      city: { required },
    };

    const vEditAddress = useVuelidate(addressToEditRules, addressToEdit) as any;

    const getStateName = () => {
      if (storeX.state.countryStates) {
        for (let i = 0; i < storeX.state.countryStates.length; i++) {
          const state = storeX.state.countryStates[i];
          if (state.id == addressToEdit.region_id) {
            addressToEdit.state = state.name;
            return state.name;
          }
        }
      }
    };

    const changeCityAction = (cityId: any) => {
      addressToEdit.city_id = cityId;
      for (let i = 0; i < stateCities.value.length; i++) {
        const city = stateCities.value[i];
        if (city.id == addressToEdit.city_id) {
          addressToEdit.city = city.name;
        }
      }
    };

    const changeStateAction = (selectedState: any) => {
      addressToEdit.region_id = selectedState;
      getStateName();
      if (selectedState != "0") {
        gettingCities.value = true;
        httpApp
          .sendPostRequest(settingsApp.getEndpointUrl("getCitiesByState"), {
            store_view: getLocalStorageData("storeCode"),
            state_id: selectedState,
          })
          .then(async (response: any) => {
            gettingCities.value = false;
            if (response.success) {
              stateCities.value = response.items;
              if (props.address.city_id) {
                addressToEdit.city_id = props.address.city_id;
                changeCityAction(addressToEdit.city_id);
              }
            } else {
              // if (te("apiMsgs." + resp.error.code)) {
              //   openToast(t("apiMsgs." + resp.error.code), "danger");
              // } else {
              //   openToast(resp.error.message, "danger");
              // }
            }
          })
          .catch((error) => {
            gettingCities.value = false;
            console.log(error);
          });
      }
    };

    if (props.address.region_id) {
      stateCities.value = [];
      changeStateAction(props.address.region_id);
    }

    const editAddressAction = async (event) => {
      event.preventDefault();
      vEditAddress.value.$touch();
      if (vEditAddress.value.$invalid) {
        return false;
      }

      let sentParams = {};

      let url = settingsApp.getEndpointUrl("addAddress");
      if (Number(addressToEdit.entity_id) > 0) {
        url = settingsApp.getEndpointUrl("editAddress");
        sentParams = {
          address_id: addressToEdit.entity_id,
          address: {
            address_label: addressToEdit.address_label,
            firstname: addressToEdit.firstname,
            lastname: addressToEdit.lastname,
            telephone: addressToEdit.telephone,
            streets: addressToEdit.street1,
            region_id: addressToEdit.region_id,
            region: addressToEdit.state,
            city: addressToEdit.city,
            city_id: addressToEdit.city_id,
            country_id: countryCode,
            epowerbackend_lat: addressToEdit.epowerbackend_lat,
            epowerbackend_lng: addressToEdit.epowerbackend_lng,
            is_default_shipping: addressToEdit.is_default_shipping,
            is_default_billing: addressToEdit.is_default_shipping,
          },
        };
      } else {
        sentParams = {
          address_id: addressToEdit.entity_id,
          address_label: addressToEdit.address_label,
          firstname: addressToEdit.firstname,
          lastname: addressToEdit.lastname,
          telephone: addressToEdit.telephone,
          streets: addressToEdit.street1,
          region_id: addressToEdit.region_id,
          region: addressToEdit.state,
          city: addressToEdit.city,
          city_id: addressToEdit.city_id,
          country_id: countryCode,
          epowerbackend_lat: addressToEdit.epowerbackend_lat,
          epowerbackend_lng: addressToEdit.epowerbackend_lng,
          is_default_shipping: addressToEdit.is_default_shipping,
          is_default_billing: addressToEdit.is_default_shipping,
        };
      }

      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(url, sentParams)
        .then(async (response: any) => {
          if (response.success) {
            props.dismiss();
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error);
        });
    };

    return {
      t,
      tm,
      icons,
      addressToEdit,
      vEditAddress,
      editAddressAction,
      getStateName,
      changeStateAction,
      changeCityAction,
      stateCities,
      gettingCities,
      props,
      mapService,
      addressGoogleApiKey,
      addressPosition,
      mapClicked,
      addressDragend,
      getPosition,
      showMap,
      storeX,
    };
  },
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    GoogleMap,
    Marker,
    CustomControl,
    LMap,
    LTileLayer,
    LMarker,
    LControl,
  },
});
