import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "col-lg-3" }
const _hoisted_6 = {
  class: "mapContainer",
  style: {"width":"100%","height":"250px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_l_control = _resolveComponent("l-control")!
  const _component_l_map = _resolveComponent("l-map")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_CustomControl = _resolveComponent("CustomControl")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "whiteBackground ion-padding" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("account.myAddressForm")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "close ion-float-end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.props.dismiss()))
            }, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.icons.close,
                color: "danger"
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _createVNode(_component_ion_list, { class: "formList" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, {
              class: "form-control",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.pencilOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.addressToEdit.address_label,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addressToEdit.address_label) = $event)),
                  name: "address_label",
                  inputmode: "text",
                  type: "text",
                  placeholder: _ctx.t('account.addressLabel')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.firstname.$errors.length,
        }]),
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.personOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.addressToEdit.firstname,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addressToEdit.firstname) = $event)),
                  name: "firstname",
                  inputmode: "text",
                  type: "text",
                  placeholder: _ctx.t('signUp.firstName')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_item, {
              class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.lastname.$errors.length,
        }]),
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.personOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.addressToEdit.lastname,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addressToEdit.lastname) = $event)),
                  name: "lastname",
                  inputmode: "text",
                  type: "text",
                  placeholder: _ctx.t('signUp.lastName')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_item, {
              class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.telephone.$errors.length,
        }]),
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.callOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.addressToEdit.telephone,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addressToEdit.telephone) = $event)),
                  name: "telephone",
                  inputmode: "text",
                  type: "tel",
                  placeholder: _ctx.t('signUp.mobile')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_item, {
              class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.street1.$errors.length,
        }]),
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.mapOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.addressToEdit.street1,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addressToEdit.street1) = $event)),
                  name: "street",
                  inputmode: "text",
                  type: "text",
                  placeholder: _ctx.t('account.address')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_item, {
              class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.state.$errors.length,
        }]),
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.icons.mapOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("account.state")), 1)
                  ]),
                  _: 1
                }),
                (_ctx.storeX.state.countryStates)
                  ? (_openBlock(), _createBlock(_component_ion_select, {
                      key: 0,
                      value: _ctx.addressToEdit.region_id,
                      onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeStateAction($event.target.value))),
                      interface: "popover"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeX.state.countryStates, (state, stateIndx) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: 'state_' + stateIndx,
                            textContent: _toDisplayString(state.name),
                            value: state.id,
                            selected: state.id == _ctx.addressToEdit.region_id
                          }, null, 8, ["textContent", "value", "selected"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"]))
                  : (_openBlock(), _createBlock(_component_ion_input, {
                      key: 1,
                      modelValue: _ctx.addressToEdit.state,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addressToEdit.state) = $event)),
                      name: "state",
                      inputmode: "text",
                      type: "text",
                      placeholder: _ctx.t('account.state')
                    }, null, 8, ["modelValue", "placeholder"]))
              ]),
              _: 1
            }, 8, ["class"]),
            (!_ctx.gettingCities)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  class: _normalizeClass(["form-control", {
          'is-invalid': _ctx.vEditAddress.city.$errors.length,
        }]),
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "stacked" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.icons.mapOutline
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("account.city")), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.stateCities.length)
                      ? (_openBlock(), _createBlock(_component_ion_select, {
                          key: 0,
                          value: _ctx.addressToEdit.city_id,
                          onIonChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeCityAction($event.target.value))),
                          interface: "popover"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateCities, (city, cityIndx) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: 'city_' + cityIndx,
                                textContent: _toDisplayString(city.name),
                                value: city.id,
                                selected: city.id == _ctx.addressToEdit.city_id
                              }, null, 8, ["textContent", "value", "selected"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value"]))
                      : (_openBlock(), _createBlock(_component_ion_input, {
                          key: 1,
                          modelValue: _ctx.addressToEdit.city,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addressToEdit.city) = $event)),
                          name: "city",
                          inputmode: "text",
                          type: "text",
                          placeholder: _ctx.t('account.city')
                        }, null, 8, ["modelValue", "placeholder"]))
                  ]),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_item, {
              class: "form-control",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("account.setAsDefaultAddress")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  modelValue: _ctx.addressToEdit.is_default_shipping,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addressToEdit.is_default_shipping) = $event)),
                  onIonChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.addressToEdit.is_default_billing == $event.target.value)),
                  interface: "popover"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, {
                      textContent: _toDisplayString(_ctx.t('no')),
                      value: 0
                    }, null, 8, ["textContent"]),
                    _createVNode(_component_ion_select_option, {
                      textContent: _toDisplayString(_ctx.t('yes')),
                      value: 1
                    }, null, 8, ["textContent"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.showMap && _ctx.mapService == 'osm_leaflet')
            ? (_openBlock(), _createBlock(_component_l_map, {
                key: 0,
                zoom: 15,
                center: _ctx.addressPosition.lat
          ? _ctx.addressPosition
          : {
            lat: 30.04442,
            lng: 31.235712,
          }
          
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_l_tile_layer, { url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }),
                  (_ctx.addressPosition.lat)
                    ? (_openBlock(), _createBlock(_component_l_marker, {
                        key: 0,
                        "lat-lng": _ctx.addressPosition,
                        draggable: "",
                        onMoveend: _cache[12] || (_cache[12] = ($event: any) => (_ctx.addressDragend($event)))
                      }, null, 8, ["lat-lng"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_l_control, { position: "topleft" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        color: "white",
                        class: "position-btn",
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getPosition(true)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.icons.locateOutline,
                            color: "dark"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["center"]))
            : _createCommentVNode("", true),
          (_ctx.showMap && _ctx.mapService == 'google_maps')
            ? (_openBlock(), _createBlock(_component_GoogleMap, {
                key: 1,
                "api-key": _ctx.addressGoogleApiKey,
                style: {"width":"100%","height":"250px"},
                center: _ctx.addressPosition,
                zoom: 15
              }, {
                default: _withCtx(() => [
                  (_ctx.addressPosition.lat)
                    ? (_openBlock(), _createBlock(_component_Marker, {
                        key: 0,
                        options: {
            position: _ctx.addressPosition.lat
              ? _ctx.addressPosition
              : { lat: 30.04442, lng: 31.235712 },
            draggable: true,
          },
                        onDragend: _cache[14] || (_cache[14] = ($event: any) => (_ctx.addressDragend($event)))
                      }, null, 8, ["options"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_CustomControl, { position: "TOP_LEFT" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        color: "white",
                        class: "position-btn",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.getPosition(true)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.icons.locateOutline,
                            color: "dark"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["api-key", "center"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ion_row, {
          class: "text-center",
          "responsive-sm": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, { size: "12" }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-heading btn-block",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.editAddressAction($event)))
                }, _toDisplayString(_ctx.t("login.submit")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}