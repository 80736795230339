import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-10 m-auto" }
const _hoisted_6 = { class: "row d-block" }
const _hoisted_7 = { class: "col-md-3 float-start" }
const _hoisted_8 = { class: "col-md-9 float-start positionRelative account" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-9" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "col-lg-3" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = {
  key: 0,
  class: "col-lg-6"
}
const _hoisted_18 = { class: "card cardWithBorder mb-3 mb-lg-0" }
const _hoisted_19 = { class: "card-header" }
const _hoisted_20 = ["textContent"]
const _hoisted_21 = { class: "card-body" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-lg-6" }
const _hoisted_25 = { class: "col-lg-6" }
const _hoisted_26 = { class: "card cardWithBorder mb-3 mb-lg-0" }
const _hoisted_27 = { class: "card-header" }
const _hoisted_28 = ["textContent"]
const _hoisted_29 = { class: "card-body" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-lg-6" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "col-lg-6" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_AccountNavComponent = _resolveComponent("AccountNavComponent")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_AccountNavComponent)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.t("account.manageAddresses")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  class: "btn btn-heading btn-block ion-float-end",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddAddressModal()))
                                }, _toDisplayString(_ctx.t("account.add")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              (_ctx.defaultAddress)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    _createElementVNode("div", _hoisted_18, [
                                      _createElementVNode("div", _hoisted_19, [
                                        _createElementVNode("h3", {
                                          class: "mb-0",
                                          textContent: _toDisplayString(_ctx.defaultAddress.address_label)
                                        }, null, 8, _hoisted_20)
                                      ]),
                                      _createElementVNode("div", _hoisted_21, [
                                        _createElementVNode("address", {
                                          innerHTML: _ctx.defaultAddress.formated_html
                                        }, null, 8, _hoisted_22),
                                        _createElementVNode("div", _hoisted_23, [
                                          _createElementVNode("div", _hoisted_24, [
                                            _createElementVNode("a", {
                                              href: "javascript:void(0)",
                                              class: "outline",
                                              onClick: _cache[1] || (_cache[1] = ($event: any) => (
                                      _ctx.openEditAddressModal(_ctx.defaultAddress)
                                      ))
                                            }, _toDisplayString(_ctx.t("account.edit")), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_25, [
                                            _createElementVNode("a", {
                                              href: "javascript:void(0)",
                                              class: "outline ion-float-end",
                                              onClick: _cache[2] || (_cache[2] = ($event: any) => (
                                      _ctx.deleteAddress(_ctx.defaultAddress.entity_id)
                                      ))
                                            }, _toDisplayString(_ctx.t("account.delete")), 1)
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.addresses.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.addresses, (address, addressIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "col-lg-6",
                                      key: addressIndex
                                    }, [
                                      _createElementVNode("div", _hoisted_26, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createElementVNode("h3", {
                                            class: "mb-0",
                                            textContent: _toDisplayString(address.address_label)
                                          }, null, 8, _hoisted_28)
                                        ]),
                                        _createElementVNode("div", _hoisted_29, [
                                          _createElementVNode("address", {
                                            innerHTML: address.formated_html
                                          }, null, 8, _hoisted_30),
                                          _createElementVNode("div", _hoisted_31, [
                                            _createElementVNode("div", _hoisted_32, [
                                              _createElementVNode("a", {
                                                href: "javascript:void(0)",
                                                class: "outline",
                                                onClick: ($event: any) => (_ctx.openEditAddressModal(address))
                                              }, _toDisplayString(_ctx.t("account.edit")), 9, _hoisted_33)
                                            ]),
                                            _createElementVNode("div", _hoisted_34, [
                                              _createElementVNode("a", {
                                                href: "javascript:void(0)",
                                                class: "outline ion-float-end",
                                                onClick: ($event: any) => (
                                        _ctx.deleteAddress(address.entity_id)
                                        )
                                              }, _toDisplayString(_ctx.t("account.delete")), 9, _hoisted_35)
                                            ])
                                          ])
                                        ])
                                      ])
                                    ]))
                                  }), 128))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_36, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}